const url  = 'https://ultimaworks.ltimindtree.com/api/';
const constantFile = {
   envUrl : url,
   headers : {
    headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzY3AiOlsiMjAwMCJdLCJhcGlLZXkiOmZhbHNlLCJpc3MiOiJ1bHRpbWFxYS5taW5kdHJlZS5jb20iLCJtaWQiOiI2MTA2MDk3NCIsInNvdXJjZSI6Ik1pbmR0cmVlIiwiYXVkIjoidWx0aW1hd29ya3NxYS5sdGltaW5kdHJlZS5jb20sdWx0aW1hd29ya3NxYS5sdGltaW5kdHJlZS5jb20iLCJ1cG4iOiJNMTA2MDk3NEBtaW5kdHJlZS5jb20iLCJ1bmlxdWVfbmFtZSI6Ik0xMDYwOTc0QG1pbmR0cmVlLmNvbSIsIm5iZiI6MTY5NzcwMDM1OCwibmFtZSI6IkthbmRsYXBlbGxpIE5hdmFuZWV0aGEiLCJ0aGVtZSI6IkxpZ2h0IiwiZXhwIjoxNjk3NzAzOTU4LCJpYXQiOjE2OTc3MDAzNTgsIm9yZ19jb2RlIjoiT1JHMDAwMDA0IiwiZW1haWwiOiJLYW5kbGFwZWxsaS42MTA2MDk3NEBsdGltaW5kdHJlZS5jb20iLCJzdGF0dXMiOiJBY3RpdmUifQ.Tt0XSuDO_Kz0Pt_BgUMlaIO8vtrWDJpZUyoCcFZHpe8",
      }
   },
   workspaceGroupCode : 'GRP9a8d642c32134cdfb02b16377970e9b1',
   // onClickApi : `${url}studio/v1/generic-canvas/CNVS9dc3fb35d2f84e24a775b05c2602ede8/software/NSW78c1184687a44ea79db53687d2a9c91b/onClick`,
   // profileApi : `${url}studio/v1/user/myProfile`,
   // genericCanvasWorkspaceApi : `${url}studio/v1/generic-canvas?type=my_canvas`,
   // getSoftwareListOnCanvas(canvasName, canvasCode){
   //    const url = `${this.envUrl}studio/v1/software-group?canvasType=${canvasName}&canvasCode=${canvasCode}`;
   //    return url;  
   // },
   loginAPI(credential){
      const httpOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: btoa(credential.password),
          From: credential.username,
          tokenRequired : credential.tokenRequired
        },
      };
      const httpBody = {
        From: credential.username,
        Authorization: btoa(credential.password),
        tokenRequired : credential.tokenRequired,
      };
      return [`${url}ultima-mobile/v1/mobileLogin`,httpBody,httpOptions];
      // return this.http.post(`${url}auth/v1/login`, httpBody, httpOptions);
      // .pipe(
      //   map((user) => {
      //     // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
      //     user.authdata = window.btoa(
      //       credential.username + ':' + credential.password
      //     );
      //   //  sessionStorage.setItem('currentUser', JSON.stringify(user));
      //     this.currentUserSubject.next(user);
      //     return user;
      //   })
      // );
   },
    greyColor : '#F7F7F7',
    darkBlue  : '#111732',
    blackColor : '#000000',
    blueColor : '#064674',
    whiteColor : '#fff',
    subTitlelight : '#666',
    subTitleDark : '#999',
    borderColorLight : '#CACACA',
    borderColorDark : '#363435',
    lightBlack : '#333',
    searchBackgroundLight : '#D3D3D3',
    searchBackgroundDark : '#363435',
    yellowColor : '#e3b525',
    favIcon : 'fav-icon.svg',
    toggleFavIcon : 'toggle-fav.svg',
    //approvalInbox,time and attendance
    statsApps : ["NSW3e8e4b0886be4eff9f8ef4d94ea7a2a0","NSW705e62d12e0747acb47e61dcb504aee4"],
    statsInfoSoftwareCodesObj : {
      'NSW3e8e4b0886be4eff9f8ef4d94ea7a2a0' : 'NSW43406b1db814437e8fe962c4c8b6cc27',
      'NSW705e62d12e0747acb47e61dcb504aee4' : 'NSW9cb17f529757458ca730a41be69c40fd',
      // 'NSW6461face04b34be78c7847227c414609' : 'NSW6808274caa044f858d119ce1d22e56ed'
    },
    statsKeyObj : {
      'NSW3e8e4b0886be4eff9f8ef4d94ea7a2a0' : 'IApprovalCount',
      'NSW705e62d12e0747acb47e61dcb504aee4' : 'Nc_ComplianceCount',
      'NSW6461face04b34be78c7847227c414609' : 'SwipedInData'
    },
    colorCodes : ['#6A70A9','#001954','#6C2888','#8A0054','#F0506E','#78CFDE'],
    softwareCodesList : [
      {
        "softwareName" : "iTime",
        "softwareCode" : "NSW705e62d12e0747acb47e61dcb504aee4"
      },
      {
        "softwareName": "Seat Allocation",
        "softwareCode": "NSW9ecd55526fd94561bbc832d26dc70a89"
      },
      {
        "softwareName": "View SwipeInTime",
        "softwareCode": "NSW6461face04b34be78c7847227c414609"
      },
      {
        "softwareName" : "Share location",
        "softwareCode" : "NSW6461face04b34be78c7847227c458916"
      }
    ],
    smartpaneApiKey : 'khjfgAuv3rsi9SQVUR7/R64WUKi4z967vkKpHoW45znsgwZc4tcaKvS=',
    logoutUri : 'https://login.microsoftonline.com/ff355289-721e-4dd7-a663-afec62ab9d54/oauth2/logout?post_logout_redirect_uri=https://um.ltimindtree.com/home',
    SwipedInData : {
      "id": "6756d8372bb5c602397785ff",
      "psNumber": "61080572",
      "date": "2025-01-17",
      "timeRange": "2025-01-17 07:00:51.000",
      "swipeBuilding": "LTIMindtree - T1, Innovation Campus, Chennai",
      "swipeCity": "Chennai",
      "direction": "IN",
      "createdOn": "2024-12-19T11:20:00.713+00:00",
      "onsiteOffShore" : "Offshore"
   },
   monthArr : {
    '1': 'Jan', '2': 'Feb', '3': 'Mar', '4': 'Apr', '5': 'May', '6': 'Jun', '7': 'Jul', '8': 'Aug', '9': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec',
  },
  completedValue : `You have completed 06:00 hrs from first swipe`
};


export default constantFile;