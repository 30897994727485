import axios from "axios";
import AuthService from './AuthService';
import constantFile from "./Constant";

const api = axios.create({
    baseURL : 'https://ultimaworks.ltimindtree.com/api/',
});
const authService = AuthService;

//Requst interceptor for adding the bearer token
api.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('mobileAppAccessToken');
        if(token) {
            // let validToken = authService.checkTokenExpiry(token);
            // console.log("valid Token",validToken);
            // if(!validToken){
            //     let credentials = {};
            //     let userName = 'M1060974@mindtree.com';
            //     let userName = sessionStorage.getItem('teamsTokenUpn');
            //     credentials['username'] = userName;
            //     credentials['password'] = 'Welcome@123';
            //     credentials['tokenRequired'] = 'token';
            //     callLogin(credentials,config);
            //     console.log("inteceptors in accessToken",sessionStorage.getItem('mobileAppAccessToken'));
            // }
           config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//Add a response interceptor for refresh token
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        // console.log("asyn in call in API***",error);
        const originalRequest = error.config;
        const token = sessionStorage.getItem('mobileAppAccessToken');
        let validToken = authService.checkTokenExpiry(token);
        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        // if(error.response.status === 500 && !originalRequest._retry){
         if(!validToken && !originalRequest._retry){
            originalRequest._retry = true;
            // console.log("insideee***",error.response);
            try{
                let credentials = {};
                // let userName = 'M1060974@mindtree.com';
                let userName = (sessionStorage.getItem('userName')) ? sessionStorage.getItem('userName') : sessionStorage.getItem('teamsTokenUpn');
                credentials['username'] = userName;
                credentials['password'] = 'Welcome@123';
                credentials['tokenRequired'] = 'token';
                let loginapiUrls = constantFile?.loginAPI(credentials);
                let response = await axios.post(loginapiUrls[0],loginapiUrls[1],loginapiUrls[2]);
                console.log("response in API.JS",response.data);
                sessionStorage.setItem('mobileAppAccessToken',`${response.data['accessToken']}`);
                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${response.data['accessToken']}`;
                return axios(originalRequest);
            }
            catch(error){
                console.log("err login in interceptors" ,error);
            }
        }
        return Promise.reject(error);
    }
);

// const callLogin = async(credentials,config) => {
//     console.log("login api in interceptors");
//     try{
//       let loginapiUrls = constantFile?.loginAPI(credentials);
//       let response = await axios.post(loginapiUrls[0],loginapiUrls[1],loginapiUrls[2]);
//       sessionStorage.setItem('mobileAppAccessToken',`${response.data['accessToken']}`);
//       config.headers.Authorization = `Bearer ${response.data['accessToken']}`;
//     }
//     catch(err){
//       console.log("err in catch login",err);
//     }
// }

//API Endpoints
export const profileApi = (properties) =>{
    return api.post('studio/v1/user/myProfile',properties);
}

export const genericCanvasWorkspaceApi = () => {
    // return api.get(`studio/v1/generic-canvas?type=my_canvas`);
    // return api.get(`studio/v1/generic-canvas?type=my_mobile`);
    return api.get(`ultima-mobile/v1/getMobileCanvas`);
}

export const getSoftwareListOnCanvas = (canvasName, canvasCode) => {
   return api.get(`studio/v1/software-group?canvasType=${canvasName}&canvasCode=${canvasCode}`);  
}

export const onClickApi = (canvasCode ,softwareCode,properties) =>{
    return api.post(`ultima-mobile/v1/generic-canvas/${canvasCode}/software/${softwareCode}/onClickMobile`,properties);
    // return api.post(`studio/v1/generic-canvas/${canvasCode}/software/${softwareCode}/onClick`,properties);
}

//Apps based on groupCode
export const getSoftwaresOnGroupCodeApi = (groupCode) => {
    //return api.get(`studio/v1/software-group/${groupCode}/nextgen-softwares/active`);
    return api.get(`ultima-mobile/v1/getIsMobileEnabledSoftwares`);
}

export const getPowerbiTokenApi = () => {
    return api.get(`studio/v1/dashboard/oauth-access-token?resource=https://analysis.windows.net/powerbi/api`);
}

export const addAsFavAppApi = (canvasCode,payload) => {
    // return api.post(`studio/v1/generic-canvas/updatenode/${canvasCode}`,payload);
    //return api.post(`studio/v1/generic-canvas/updateMobileNode/${canvasCode}`,payload);
     return api.post(`ultima-mobile/v1/updateMobileNode/${canvasCode}`,payload);
}

export const saveCanvas = (canvasCode, addedNode, versionNum) => {
    // return api.post(`studio/v1/generic-canvas/${canvasCode}?version=${versionNum}`,addedNode);
    return api.post(`ultima-mobile/v1/addSoftware/${canvasCode}?version=${versionNum}`,addedNode);
}
export const updateCanvas = (canvasCode,versionNum,nodeList) => {
    // return api.post(`studio/v1/generic-canvas/updateMobileNodeList/${canvasCode}?version=${versionNum}`,nodeList);
    // return api.post(`studio/v1/generic-canvas/updateNodeList/${canvasCode}?version=${versionNum}`,nodeList);
    return api.post(`ultima-mobile/v1/updateMobileNodeList/${canvasCode}?version=${versionNum}`,nodeList);
}

export const createMobileCanvas = () => {
    // return api.get(`studio/v1/create-mobile-canvas`);
    return api.post(`ultima-mobile/v1/createMobileCanvas`);
}

export const postUserStatData = (statData) => {
    // return api.post(`usage-stats/v1/statistics`,statData);
    return api.post(`ultima-mobile/v1/statisticsForMobile`,statData);
}

export const getStatsData = (appsoftwaresCodes) => {
    // return api.post(`usage-stats/v1/getLtiApps`,appsoftwaresCodes);
    return api.post(`ultima-mobile/v1/getLtiAppsForMobile`,appsoftwaresCodes);
}

export const logoutApi = (payload) => {
    return api.post(`ultima-mobile/v1/mobileLogout`,payload);
}

export const getEmployeeLocation = (data) => {
    return api.post(`ultima-mobile/v1/getAddressByLatitudeAndLongitude`,data);
}

export default api;