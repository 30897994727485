// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
// import Privacy from "./Privacy";
// import TermsOfUse from "./TermsOfUse";
// import Tab from "./Tab";
import { TeamsFxContext } from "./Context";
import config from "./sample/lib/config";
import './App.css';
import Header from "./Header/Header";
import Iframe from "./Iframe/Iframe";
import CardDetails from "./CardDetails/CardDetails";
// import * as microsoftTeams from "@microsoft/teams-js";
import AuthService from '../Shared/Utilities/AuthService';
import { useEffect , useRef , useState} from "react";
import axios from "axios";
import constantFile from "../Shared/Utilities/Constant";
import PowerBiDashboard from "./PowerBiDashboard/PowerBiDashboard";
import { setLoaderObserver } from '../Shared/Utilities/sharedDataObservables';
import { postUserStatData } from "../Shared/Utilities/API";
import { useNavigate } from "react-router-dom/dist";
import { app } from "@microsoft/teams-js";
import { loggedInUserInfo } from "../Shared/Utilities/sharedDataService";
import SwipedInfo from "./SwipedInfo/SwipedInfo";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [loader,setLoader] = useState(true);
  const navigate = useNavigate();
  const [mode, setMode] = useState('');

  // sessionStorage.setItem('mobileAppAccessToken','eyJhbGciOiJIUzI1NiJ9.eyJzY3AiOlsiMjAwMCIsIjc2MDAiXSwiYXBpS2V5IjpmYWxzZSwiaXNzIjoidWx0aW1hcWEubWluZHRyZWUuY29tIiwibWlkIjoiNjEwMjYwMDAiLCJzb3VyY2UiOiJNaW5kdHJlZSIsImF1ZCI6InVsdGltYXdvcmtzcWEubHRpbWluZHRyZWUuY29tLHVsdGltYXdvcmtzcWEubHRpbWluZHRyZWUuY29tIiwidXBuIjoiTTEwMjYwMDBAbWluZHRyZWUuY29tIiwidW5pcXVlX25hbWUiOiJNMTAyNjAwMEBtaW5kdHJlZS5jb20iLCJuYmYiOjE2OTc3MTM5NjIsIm5hbWUiOiJKaXNoYSBKb2hucyIsInRoZW1lIjoiTGlnaHQiLCJleHAiOjE2OTc3MTc1NjIsImlhdCI6MTY5NzcxMzk2Miwib3JnX2NvZGUiOiJPUkcwMDAwMDQiLCJlbWFpbCI6Ikppc2hhLjYxMDI2MDAwQGx0aW1pbmR0cmVlLmNvbSIsInN0YXR1cyI6IkFjdGl2ZSJ9.P1E0atPhOTRmvmKVeK5dl1ZOeFcRhNuNYVlNvM8lXgc');

  useEffect(() => {
    console.log("APPP JSX!!");
    setIsLoading(true);
    setLoader(true);
    getContextDetails();
    sessionStorage.setItem('AppOpen',false);
    sessionStorage.setItem('checkAppOpen',false);
    localStorage.removeItem('searchString');
    sessionStorage.removeItem('scrollPosition');
    sessionStorage.setItem('isPulltoRefresh',false);
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    if(!window.location?.ancestorOrigins?.contains('https://teams.microsoft.com') && (window.location?.href?.includes('https://mtultimamobilprod.z30.web.core.windows.net/') || window.location?.href?.includes('https://um.ltimindtree.com/')) && !window.location?.href?.includes('/index.html')) {
      console.log("from Browser***",window.location.href);
      sessionStorage.setItem('fromBrowser',true);
      if(sessionStorage.getItem('mobileAppAccessToken')){
        if(!window.location.href?.endsWith('/card')) navigate('/card');
        // let teamsInterval = setInterval(() => {
        //   if (sessionStorage.getItem('mobileAppAccessToken')) {
        //     clearInterval(teamsInterval);
        //     let credentials = {};
        //     // let userName = 'M1026000@mindtree.com';
        //     let userName = (sessionStorage.getItem('userName')) ? sessionStorage.getItem('userName') : sessionStorage.getItem('teamsTokenUpn');
        //     credentials['username'] = userName;
        //     credentials['password'] = 'Welcome@123';
        //     credentials['tokenRequired'] = 'token';
        //     callLogin(credentials);
        //   }
        // }, 200);  
      }
      else {
        if (window.location.href?.includes('home?ref=')){
          setIsLoading(false);
          setLoader(false);
          let isParsed = parseAccessToken();
          console.log("refreshTokeee**",isParsed);
          if(isParsed) { 
            getRefreshToken();
          }
          // navigate('/card');
        }
        else {
          console.log("SSO Redirectionn**");
          AuthService.ssoRedirection();
        }
      }
    }
    else {
      sessionStorage.setItem('fromBrowser',false);
      let teamsInterval = setInterval(() => {
        if (sessionStorage.getItem('mobileTeamsToken')) {
          clearInterval(teamsInterval);
          let credentials = {};
          // let userName = 'M1026000@mindtree.com';
          let userName = (sessionStorage.getItem('userName')) ? sessionStorage.getItem('userName') : sessionStorage.getItem('teamsTokenUpn');
          credentials['username'] = userName;
          credentials['password'] = 'Welcome@123';
          credentials['tokenRequired'] = 'token';
          callLogin(credentials);
        }
      }, 200);  
    }
    setLoaderObserver.getLoaderValue().subscribe((res) => {
      setLoader(res);
    })  
    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
     });
    }
  }, [])

  const getContextDetails = async () => {
    const context = await app.getContext();
    sessionStorage.setItem('themeValue',`${context.app.theme}`,sessionStorage.getItem('mobileAppAccessToken'));
    if(sessionStorage.getItem('mobileAppAccessToken')) setIsLoading(false);
  }

  const parseAccessToken = () => {
    let isTokenParsed = false;
    let urlQueryParams = window.location.search.replace('?', '');
    let refreshTokenId = urlQueryParams.split('ref=');
    sessionStorage.setItem('refreshTokenId',refreshTokenId[1]);
    // console.log("refreshTokn id**",refreshTokenId);
    // this.oauthService.getRefreshToken();
    isTokenParsed = true;
    return isTokenParsed;
  }

  const callLogin = async (credentials) => {
    try {
      let loginapiUrls = constantFile?.loginAPI(credentials);
      let response = await axios.post(loginapiUrls[0], loginapiUrls[1], loginapiUrls[2]);
      sessionStorage.setItem('mobileAppAccessToken', `${response.data['accessToken']}`);
      console.log("Login API**");
      if(sessionStorage.getItem('themeValue')) setIsLoading(false);
      let stats = (localStorage.getItem('userStatistics') && JSON.parse(localStorage.getItem('userStatistics'))?.length >= 5) ? JSON.parse(localStorage.getItem('userStatistics')) : [];
      if(stats?.length >= 5) callUserStatistics(JSON.parse(localStorage.getItem('userStatistics')));
    }
    catch (err) {
      if(sessionStorage.getItem('themeValue')) setIsLoading(false);
      // console.log("err in catch login",err);
    }
  }

  const callUserStatistics = async(stats) => {
    let payLoad = [];
    payLoad = stats.map(obj => ({
      ...obj,
     mid : loggedInUserInfo.getLoggedInUserInfo()?.mid
    }));
    console.log("payload",payLoad);
    try {
      let response = await postUserStatData(payLoad);
      localStorage.setItem('userStatistics',[]);
    }
    catch(err) {
      console.log("err in stats",err);
    }
  }

  // const getRefreshToken = async () => {
  //   try {
  //      let userName = null;
  //      let refreshTokenId = sessionStorage.getItem('refreshTokenId');
  //      let refreshTokenUrl = AuthService.getRefreshTokenAPI(userName,refreshTokenId);
  //      console.log("rddddd",refreshTokenUrl);
  //      let response = await axios.post(refreshTokenUrl[0],{
  //       headers : refreshTokenUrl[1]
  //     });
  //      console.log("resss",response);
  //   }
  //   catch (err) {
  //     console.log("errrrr",err);
  //   }
  // }

  const getRefreshToken = () => {
    let userName = (sessionStorage.getItem('teamsToken')) ? sessionStorage.getItem('teamsTokenUpn') : sessionStorage.getItem('userName');
    let refreshTokenUrl = AuthService.getRefreshTokenAPI(userName,sessionStorage.getItem('refreshTokenId'));
    let payLoad = {
      'grantType' : 'refresh_token',
      'refreshToken' : `${sessionStorage.getItem('refreshTokenId')}`,
      'username' : `${userName}`
    }
    let xhr = new XMLHttpRequest();
    xhr.open(
      'POST',
      `${refreshTokenUrl}`,
      false
    );
    xhr.withCredentials = true;
    //this is for when url has params
    // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', `${constantFile.smartpaneApiKey}`);
    // xhr.send(null);
    xhr.send(JSON.stringify(payLoad));
    if(xhr.status ===  200) {
      let response = JSON.parse(xhr.response);
      console.log("response***",response);
      if(response?.accessToken) {
        sessionStorage.setItem('mobileAppAccessToken', response?.accessToken);
        let decodedTeamsToken = AuthService.decodeToken(response?.accessToken);
        let userInfo = AuthService.readUserInfoFromToken(decodedTeamsToken);
        // console.log("decodedTokene in appjsx**",decodedTeamsToken,userInfo);
        sessionStorage.setItem('userName',userInfo?.username);
        sessionStorage.setItem('nameoftheUser',userInfo?.name);  
        sessionStorage.setItem('browser',true);
        let stats = (localStorage.getItem('userStatistics') && JSON.parse(localStorage.getItem('userStatistics'))) ? JSON.parse(localStorage.getItem('userStatistics')) : [];
        if(stats?.length > 0) callUserStatistics(JSON.parse(localStorage.getItem('userStatistics')));
      }
    }
    else {
      AuthService.callLogoutUri();
    }
  }
  const onSelectMode = (mode) => {
    sessionStorage.setItem('themeValue',mode);
    setMode(mode);
    if(sessionStorage.getItem('mobileAppAccessToken')) setIsLoading(false);
  }
  
  return (
    <>
      {isLoading ? (<Spinner style={{ margin: 100 }} />) :
        (
        <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
          <FluentProvider
            theme={
              (themeString === "dark" || mode === "dark" )
                ? {
                  ...teamsDarkTheme,
                  colorNeutralBackground3: loader ?  `${constantFile.blackColor}` :  `${constantFile.blackColor}`
                }
                : (themeString === "contrast" || mode === "dark")
                  ? {
                    ...teamsHighContrastTheme,
                    colorNeutralBackground3:  `${constantFile.blackColor}`,
                  }
                  : {
                    ...teamsLightTheme,
                    colorNeutralBackground3: loader ? `${constantFile.whiteColor}` : `${constantFile.greyColor}`
                  }
            }
            style={{ background: tokens.colorNeutralBackground3, height: '100%', display: "flex", flexDirection: "column",overflow: "hidden" }}
          // style={{ background: '#fff' , height:'100%',display:"flex",flexDirection:"column",overflow: "hidden"}}
          > 
            <Header />
            {/* <Router> */}
            {(loading || mode === '') ? (
              <Spinner style={{ margin: 100 }} />
            ) : (
                <Routes>
                  {/* <Route path="/privacy" element={<Privacy />} />
                  <Route path="/termsofuse" element={<TermsOfUse />} />
                  <Route path="/tab" element={<Tab />} />
                  <Route path="*" element={<Navigate to={"/tab"} />}></Route> */}
                  <Route path='/' element={<Navigate to={"/card"} />} />
                  <Route path='/card' element={<CardDetails />} />
                  <Route path='/iframe' element={<Iframe />} />
                  <Route path='/powerbi' element={<PowerBiDashboard />} />
                  <Route path='/swipedInTime' element={<SwipedInfo />} />
                  <Route path='*' element={<Navigate to={"/card"} />}></Route>
                </Routes>
            )}
            {/* </Router> */}
          </FluentProvider>
        </TeamsFxContext.Provider>
        )}
    </>
    // <div className="card_container">
    //   <div className="card_class">
    //     <div className="card_header">
    //         <img src="/1touch.svg" alt="image" />
    //     </div>
    //     <div className="card_description">
    //       <div className="card__title">
    //         First React App
    //       </div>
    //       <div className="card__sub__title">
    //         First app Description
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <>
    //   <Header />
    //   <Routes>
    //       <Route path='*' element={<CardDetails />} />
    //       <Route path='/card' element={<CardDetails />} />
    //       <Route path='/iframe' element={<Iframe />} />
    //   </Routes>
    // </>
  );
}
