import React, { useEffect, useState } from "react";
import { app, Context } from "@microsoft/teams-js";
import './Header.css';
import UserProfile from "../../Shared/Utilities/ProfileData";
import { profileApi , postUserStatData , logoutApi} from "../../Shared/Utilities/API";
import { useNavigate } from 'react-router-dom/dist';
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "../sample/lib/config";
import { RefreshPage, classNames } from "../../Shared/Utilities/CommonService";
import constantFile from "../../Shared/Utilities/Constant";
import { setLoaderObserver, setSearchExpandObserver, setAppClickObserver , setPullToRefreshObserver } from '../../Shared/Utilities/sharedDataObservables';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AuthService from "../../Shared/Utilities/AuthService";
import { makeStyles } from '@fluentui/react-components';
import { loggedInUserInfo } from "../../Shared/Utilities/sharedDataService";
// import { makeStyles } from "@mui/material";


const useStyles = makeStyles({
  root: {
    backgroundColor: `#fff !important`,
    '&:hover': {
      backgroundColor: `#fff !important`
    },
  },
});

const Header = () => {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });
  const [userProfile, setUserProfile] = useState({});
  const [isLoader, setLoader] = useState(true);
  const [isVisible, setVisibleHeader] = useState(false);
  const [heightChange, setHeightChange] = useState(false);
  const properties = {};
  const navigate = useNavigate();
  let constants = constantFile;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const [mode, setMode] = useState('');



  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    setLoaderObserver.getLoaderValue().subscribe((res) => {
      setLoader(res);
      checkHeaderVisibility();
    })
    setAppClickObserver.getAppClicked().subscribe((res) => {
      if(res === true) {
        setHeightChange(true);
        setVisibleHeader(false);
      }
      else {
        setHeightChange(false);
        checkHeaderVisibility(); 
      }
    })
    setSearchExpandObserver.getSearchExpand().subscribe((res) => {
      if (res == true) setVisibleHeader(false);
      else setVisibleHeader(true);
    })
    setPullToRefreshObserver.getPullToRefresh().subscribe((res) => {
      if(res === true) {
        sessionStorage.setItem('AppOpen',false);
        setHeightChange(false);
        checkHeaderVisibility();
      }
    })
    checkHeaderVisibility();
    let defaultUserObj = {};
    defaultUserObj.profileIconColor = `${getIconColorAndText()[0]}`;
    defaultUserObj.profileIconText = `${getIconColorAndText()[1]}`;
    defaultUserObj.name = sessionStorage.getItem('nameoftheUser') ? sessionStorage.getItem('nameoftheUser') : '';
    setUserProfile(defaultUserObj);
    // getProfile();
    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, [])

  const getIconColorAndText = () => {
    let iconColor = 'rgb(123, 203, 226)' ;
    let iconText  = 'PS';
    let colorsCodesAr = constants.colorCodes;
    if(sessionStorage.getItem('nameoftheUser')) {
      let empNameList = sessionStorage.getItem('nameoftheUser')?.split(' ');
      let text = '';
      let maxVal = 0xFFFFFF;
      let randomNumber = Math.random() * maxVal; 
      randomNumber = Math.floor(randomNumber);
      let randNumber = randomNumber.toString(16);
      // let randColor = randNumber.padStart(6, '0');   
      // iconColor = '#'+randColor.toUpperCase();
      iconColor = colorsCodesAr[(Math.floor(Math.random() * colorsCodesAr.length))];
      empNameList.forEach((ele, index) => {
        if(index < 3) {
          text = text + ele.substring(0, 1);
        }
      });
      iconText = text;
    }
    return [iconColor,iconText];
  }

  const checkHeaderVisibility = () => {
      if(sessionStorage.getItem('browser') === 'true') setVisibleHeader(true);
      else setVisibleHeader(false);
  }

  const getContextDetails = async () => {
    const context = await app.getContext();
  }
  const getProfile = async () => {
    try {
      // let response = await axios.post(constants?.profileApi,properties,constants?.headers);
      let response = await profileApi(properties);
      UserProfile.setUserData(response.data);
      setUserProfile(response.data);
    }
    catch (error) {
      // console.log("eror",error);
    }
  }

  const openMenu = (event) => {
    // console.log("eventt",event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToHome = () => {
    RefreshPage.setRefreshPage(true);
    navigate('/card');
  }
  
  const logout = () => {
    let userName = sessionStorage.getItem('userName');
    if(localStorage.getItem('userStatistics')) {
      let startsArr = JSON.parse(localStorage.getItem('userStatistics'));
      if(startsArr?.length !== 0) callUserStatistics(startsArr);
    }
    setTimeout(() => {
      // window.sessionStorage.clear();
      // window.localStorage.clear();
      // AuthService.logout(userName);
      callLogout(userName);
    }, 1000)
  }

  const callLogout = async(userName) => {
    let logoutUri  = constants.logoutUri;
    let payload  = {
      'refTokenId' : `${sessionStorage.getItem('refreshTokenId')}`,
      'userName' : userName
    };
    window.sessionStorage.clear();
    window.localStorage.clear();
    try {
      let response = await logoutApi(payload);
      if(AuthService.isValidUrl(logoutUri,userName)) {
        window.location.replace(logoutUri);
      }
    }
    catch(err) {
      if(AuthService.isValidUrl(logoutUri,userName)) window.location.href = logoutUri;
    }
  }

  const onSelectMode = (mode) => {
    setMode(mode);
    sessionStorage.setItem('themeValue',mode);
  }

  const callUserStatistics = async(stats) => {
    let payLoad = [];
    payLoad = stats.map(obj => ({
      ...obj,
     mid : loggedInUserInfo.getLoggedInUserInfo()?.mid
    }));
    try {
      let response = await postUserStatData(payLoad);
      localStorage.setItem('userStatistics',[]);
    }
    catch(err) {
      console.log("err in stats",err);
    }
  }

  return (
    <>
      {/* { !isLoader &&  */}
      {(!loading || (mode !== '' && sessionStorage.getItem('browser') === 'true')) && 
        <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
          <FluentProvider
            theme={
              (themeString === "dark" || mode === "dark")
                ? {
                  ...teamsDarkTheme,
                  colorNeutralBackground3: `${constants.blackColor}`,
                }
                : (themeString === "contrast" || mode === "dark")
                  ? {
                    ...teamsHighContrastTheme,
                    colorNeutralBackground3: `${constants.blackColor}`,
                  }
                  : {
                    ...teamsLightTheme,
                    colorNeutralBackground3:  isLoader ? `${constantFile.whiteColor}` : `${constantFile.greyColor}`
                  }
            }
            style={{ background: tokens.colorNeutralBackground3 }}
          >
            <div className={classNames('header_container', isLoader && 'header_height_change' , heightChange && 'header_height_change_OnAppOpen' )}>
              <div className="header_left_header">
                {
                  (isVisible === true && !isLoader) &&
                  <img src="/yellow_lti_logo.svg" alt="ultima" className="logo_class" onClick={navigateToHome} />
                }
                {/* <div className="header__title">Ultima Mobile</div> */}
              </div>
              { (userProfile?.name && !isLoader && isVisible === true) &&
                <div className="right_header">
                  <div className="profile_class">
                    <div className="profile__name">{userProfile?.name}</div>
                    <div className="profile_designation">{userProfile?.userDesignation}</div>
                  </div>
                  <div className="profile_image" onClick={openMenu}>
                    {
                      userProfile?.profilePic ? <img className="img_circle"
                        src={`data:image/jpeg;base64,${userProfile?.profilePic}`} alt="profile" /> : <div className="profile_icon" style={{ backgroundColor: userProfile?.profileIconColor }}>{userProfile.profileIconText}</div>
                    }
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 16,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <MenuItem className={classes.root} sx={{ font: "normal 0.8rem SourceSansProSemiBold" , padding: '2px 24px', minHeight : '20px !important' }} onClick={logout}>
                      {/* <ListItemIcon>
                      </ListItemIcon> */}
                        Logout
                      </MenuItem>
                    </Menu>
                </div>
              } 
            </div>
          </FluentProvider>
        </TeamsFxContext.Provider>
      }
      {/* } */}
    </>
  )
}

export default Header;