import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './SwipedInfo.css';
import '../Iframe/Iframe.css';
import { useNavigate } from "react-router-dom/dist";
import {
    FluentProvider,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    tokens,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "../sample/lib/config";
import constantFile from '../../Shared/Utilities/Constant';
import { setAppClickObserver, setPullToRefreshObserver } from '../../Shared/Utilities/sharedDataObservables';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { classNames, getFormateDate } from "../../Shared/Utilities/CommonService";
import { pullToRefreshEnable } from "../../Shared/Utilities/sharedDataService";
import WorkspaceInfo, { WorkspaceAppsInfo, SwipedInformation } from "../../Shared/Utilities/WorkspaceInfo";
import { getStatsData } from '../../Shared/Utilities/API';

const SwipedInfo = () => {
    const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
        initiateLoginEndpoint: config.initiateLoginEndpoint,
        clientId: config.clientId,
    });
    const location = useLocation();
    const navigate = useNavigate();
    const [mode, setMode] = useState('');
    const [swipedData, setSwipedData] = useState(null);
    const [clearTimeInterval, setClearTimeInterval] = useState(false);
    const [displayTimer, setDisplayTimer] = useState('');
    const [noData, setNoData] = useState('Please wait while we fetch the data!...');
    const [swipedInfo, setSwipedInfo] = useState({});
    const [progress, setProgress] = useState(0);
    const data = location.state;
    let constants = constantFile;

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));
        onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
        setAppClickObserver.sendAppClicked(true);
        sessionStorage.setItem('checkAppOpen', true);
        // if (SwipedInformation.getSwipedInData() === null || SwipedInformation.getSwipedInData() === undefined) {
        //     getSwipedDataInfo();
        // }
        // else {
        setSwipedData(SwipedInformation.getSwipedInData());
        formatedSwipedInfo(SwipedInformation.getSwipedInData());
        // }
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
            });
        }
    }, []);

    const getSwipedDataInfo = async () => {
        let softwareList = [data.softwareCode];
        try {
            let response = await getStatsData(softwareList);
            // let response = await getStatsDataQAAPI(softwareList);
            let data = response?.data;
            if (data?.SwipedInData === null || data?.SwipedInData === undefined || data?.SwipedInData === '') {
                SwipedInformation.setSwipedInData("No DATA");
                setNoData('No Swiped-in Time Available');
                // setSwipedData(constants.SwipedInData);
                // formatedSwipedInfo(constants.SwipedInData);
            }
            else {
                SwipedInformation.setSwipedInData(data?.SwipedInData);
                setSwipedData(data?.SwipedInData);
                formatedSwipedInfo(swipedData);
            }
        }
        catch (error) {
            setNoData('No Swiped-in Time Available');
        }
    }

    const closeDashboard = () => {
        SwipedInformation.setSwipedInData(null);
        setAppClickObserver.sendAppClicked(false);
        navigate('/card');
    }

    const handleRefresh = () => {
        SwipedInformation.setSwipedInData(null);
        pullToRefreshEnable.setPullToRefresh(true);
        sessionStorage.setItem('AppOpen', false);
        sessionStorage.removeItem('scrollPosition');
        if (WorkspaceInfo.getWorkspacInfo()?.length !== 0 || WorkspaceAppsInfo.getWorkspaceSoftwareList()?.length !== 0) sessionStorage.setItem('isPulltoRefresh', true);
        else sessionStorage.setItem('isPulltoRefresh', false);
        setPullToRefreshObserver.sendPullToRefresh(true);
        navigate('/card');
    }

    const formatedSwipedInfo = (swipedInData) => {
        if (getFormateDate(new Date()) === getFormateDate(swipedInData?.date) && swipedInData?.onsiteOffShore?.toLowerCase() === "offshore") {
            setNoData('');
            let list = {};
            let swipedInfo = swipedInData;
            let hours = (new Date(swipedInfo?.timeRange)?.getHours() < 10) ? `0${new Date(swipedInfo?.timeRange)?.getHours()}` : new Date(swipedInfo?.timeRange)?.getHours();
            let mins = (new Date(swipedInfo?.timeRange)?.getMinutes() < 10) ? `0${new Date(swipedInfo?.timeRange)?.getMinutes()}` : new Date(swipedInfo?.timeRange)?.getMinutes();
            let today_swipedTime = `${hours}:${mins}`;
            let postFixTime = (parseFloat(hours) >= 12) ? 'PM' : 'AM';
            list['subTitle_1'] = `Today, ${getFormateDate(swipedInfo?.date)}`;
            list['subTitle_2'] = `You Swiped in at <b>${today_swipedTime} ${postFixTime}</b>`;
            list['timer_value'] = `Time since first swipe`;
            setSwipedInfo(list);
            runTimer(swipedInfo?.timeRange);
        }
        else {
            setNoData('No Swiped-in Time Available');
            // setSwipedData(constants.SwipedInData);
            // formatedSwipedInfo(constants.SwipedInData);
        }
    }

    const onSelectMode = (mode) => {
        setMode(mode);
    }

    const runTimer = (swipedTimeRange) => {
        setClearTimeInterval(false);
        let startDate = new Date(swipedTimeRange);
        // let endDate = new Date();
        let currentDate = new Date();
        let finalendDate = currentDate.toLocaleString('en-US', { timeZone: "Asia/Kolkata" });
        let endDate = new Date(finalendDate);
        let diffinmilliSec = Math.abs(endDate - startDate);
        let diifInMin = Math.floor(diffinmilliSec / (1000 * 60)); // convert to min
        let hrs = Math.floor(diifInMin / 60); // convert to hours
        let min = diifInMin % 60; // get remaining minutes
        let hrsPrefix = (hrs < 10) ? `0${hrs}` : `${hrs}`;
        let minPrefix = (min < 10) ? `0${min}` : `${min}`;
        // let progress = Math.min((hrs/6)*100,100);
        let elapsedHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
        let progress = Math.floor(Math.min((elapsedHours / 6) * 100, 100));
        setProgress(progress);
        if (hrs >= 6) {
            // this.displayTimer = `${hrsPrefix}: ${minPrefix}`;
            setDisplayTimer(`06:00`);
            setClearTimeInterval(true);
        }
        else {
            setDisplayTimer(`${hrsPrefix}: ${minPrefix}`);
            const timerInterval = setInterval(() => {
                let startDate = new Date(swipedTimeRange);
                // let endDate = new Date();
                let currentDate = new Date();
                let finalendDate = currentDate.toLocaleString('en-US', { timeZone: "Asia/Kolkata" });
                let endDate = new Date(finalendDate);
                let diffinmilliSec = Math.abs(endDate - startDate);
                let diifInMin = Math.floor(diffinmilliSec / (1000 * 60)); // convert to min
                let hrs = Math.floor(diifInMin / 60); // convert to hours
                let min = diifInMin % 60; // get remaining minutes
                hrsPrefix = (hrs < 10) ? `0${hrs}` : `${hrs}`;
                minPrefix = (min < 10) ? `0${min}` : `${min}`;
                setDisplayTimer(`${hrsPrefix} : ${minPrefix}`);
                let elapsedHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
                let progress = Math.floor(Math.min((elapsedHours / 6) * 100, 100));
                setProgress(progress);
                if (hrs >= 6) {
                    setDisplayTimer(`06:00`);
                    //   this.clearTimeInterval = true;
                    setClearTimeInterval(true);
                    window.clearInterval(timerInterval);
                }
            }, 61000);
        }
    }

    return (
        <PullToRefresh onRefresh={handleRefresh} resistance={5}>
            <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
                <FluentProvider
                    theme={
                        (themeString === "dark" || mode === "dark")
                            ? {
                                ...teamsDarkTheme,
                                colorNeutralBackground3: `${constants.blackColor}`,
                                colorNeutralForeground1: `${constants.whiteColor}`,
                                colorNeutralBackground2: `${constants.blackColor}`,
                                colorNeutralForeground3: `${constants.subTitlelight}`
                            }
                            : (themeString === "contrast" || mode === "dark")
                                ? {
                                    ...teamsHighContrastTheme,
                                    colorNeutralBackground3: `${constants.blackColor}`,
                                    colorNeutralForeground1: `${constants.whiteColor}`,
                                    colorNeutralBackground2: `${constants.blackColor}`,
                                    colorNeutralForeground3: `${constants.subTitlelight}`
                                }
                                : {
                                    ...teamsLightTheme,
                                    colorNeutralBackground3: `${constants.greyColor}`,
                                    colorNeutralForeground1: `${constants.blackColor}`,
                                    colorNeutralBackground2: `${constants.whiteColor}`,
                                    colorNeutralForeground3: `${constants.subTitleDark}`
                                }
                    }
                    style={{ background: tokens.colorNeutralBackground3 }}
                >
                    <div className="swipedData_class" style={{ background: tokens.colorNeutralBackground2 }}>
                        <div className="iframe_header" style={{ background: tokens.colorNeutralBackground3 }}>
                            <div className="left_header">
                                {(themeString === `dark` || themeString === `contrast` || mode === `dark`) ? <img className="back_arrow" src="/chevron_left_white.svg" alt="backImg" onClick={closeDashboard} /> :
                                    <img className="back_arrow" src="/chevron_left.svg" alt="backImg" onClick={closeDashboard} />}
                                <div className="title_class">
                                    <div className="dashboard_img">
                                        <img src={data?.iconUrl} className="image_class" alt="icon" />
                                    </div>
                                    <div className="dashboard_title" style={{ color: tokens.colorNeutralForeground1 }}>{data?.softwareName}</div>
                                </div>
                            </div>

                        </div>
                        <div className="swiped_info_container" style={{ background: tokens.colorNeutralBackground2 }}>
                            {!(noData === '') ? <div className="no_data">{noData}</div> :
                                <div className="swiped_info">
                                    <div className="today_date_title" style={{ color: tokens.colorNeutralForeground1 }} dangerouslySetInnerHTML={{ __html: swipedInfo?.subTitle_1 }}></div>
                                    <div className="today_date_title2" style={{ color: tokens.colorNeutralForeground1 }} dangerouslySetInnerHTML={{ __html: swipedInfo?.subTitle_2 }}></div>
                                    {/* <div className={classNames('timer_class', clearTimeInterval === true && 'green_Class')}>{displayTimer?.split(":")[0]} <span className="bottom_text">hrs</span> : {displayTimer?.split(":")[1]} <span className="bottom_text">mins</span></div> */}
                                    <div className="progress-circle-container">
                                        <svg width="220" height="220" viewBox="0 0 36 36" className="circular-chart">
                                            <path className="circle-bg" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                            <path className={classNames('circle', (clearTimeInterval === true) ? 'green_stoke' : 'skyBlue')} strokeDasharray={`${progress}, 100`}
                                                d="M18 2.0845  
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                            <text x="18" y="20" className="percentage" style={{ fill: tokens.colorNeutralForeground1 }}>{displayTimer}</text>
                                            <text x="13" y="23" className="hrs_min_class" style={{ fill: tokens.colorNeutralForeground1 }}>hrs</text>
                                            <text x="20" y="23" className="hrs_min_class" style={{ fill: tokens.colorNeutralForeground1 }}>mins</text>
                                        </svg>
                                    </div>
                                    <div className="timer_value" style={{ color: tokens.colorNeutralForeground1 }}><i>{swipedInfo?.timer_value}</i></div>
                                    {/* <div className="timer_value" style={{ color: tokens.colorNeutralForeground1 }}><i>{(clearTimeInterval === true) ? constants?.completedValue : swipedInfo?.timer_value}</i></div> */}
                                </div>
                            }
                        </div>
                    </div>
                </FluentProvider>
            </TeamsFxContext.Provider>
        </PullToRefresh>
    )


}
export default SwipedInfo;